@font-face {
    font-family: 'barlowregular';
    src: url('../../../Fonts/fonts/barlow-regular-webfont.woff2') format('woff2'),
        url('../../../Fonts/fonts/barlow-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotoregular';
    src: url('../../../Fonts/fonts/roboto-regular-webfont.woff2') format('woff2'),
        url('../../../Fonts/fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    .subContainer {
        margin-top: 22%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .title {
            font-family: "barlowregular";
            font-style: normal;
            font-weight: 400;
            font-size: 34px;
            line-height: 2.6vw;
            text-align: center;
            letter-spacing: 0.25px;
            color: #0f2453;
            height: 40px;
        }

        .subTitle {
            font-family: "barlowregular";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 2.1vw;
            text-align: center;
            color: rgba(33, 33, 33, 0.6);
            margin-bottom: 8%;
        }

        .optionContainer {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .cardOne,
            .cardTwo {
                width: 20vw;
                // margin-right: 2vh;
                padding: 2vh 3vh 1vh 3vh;
                border: 1px solid rgba(33, 33, 33, 0.6);
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                
                
                .cardTitle {
                    font-family: "barlowregular";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 1.8vw;
                    letter-spacing: 0.15px;
                    color: #0f2453;
                }

                .cardSubTitle {
                    font-family: "barlowregular";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 1.8vw;
                    letter-spacing: 0.1px;
                    color: rgba(33, 33, 33, 0.6);
                }

                .feetContainer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 10%;

                    .feetTitle {
                        font-family: "robotoregular";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 1.4vw;
                        letter-spacing: 0.15px;
                        color: #14395b;
                    }

                    .maxTitle {
                        font-family: "robotoregular";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 1.1vw;
                        letter-spacing: 0.4px;
                        color: rgba(33, 33, 33, 0.6);
                    }
                }



                .slider {
                    margin-top: 20%;
                }

                .checbBoxContainer {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .checkBtn{
                        background: rgba(33, 33, 33, 0.6);
                        margin-right: 1vh;
                    }
                     input[type=checkbox] {
                        accent-color: #0f2453;
                    }
                    .checkLabel{
                        font-family: "robotoregular";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 6px;
                        letter-spacing: 0.4px;
                        color: rgba(0, 0, 0, 0.7);
                        bottom: 2.5vh;
                    }
                    .checkTitle {
                        font-family: "robotoregular";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 6px;
                        letter-spacing: 0.4px;
                        color: rgba(0, 0, 0, 0.7);
                    }

                    .check input[type="checkbox" i] {
                        accent-color: #0f2453;
                    }
                }

                .roomSelectContainer {
                    margin-top: 10%;
                    margin-bottom: 10%;

                    .roomTitle {
                        font-family: "robotoregular";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 1.5vw;
                        letter-spacing: 0.15px;
                        color: #14395b;
                        margin-bottom: 5%;
                    }

                    .drop {
                        font-family: 'barlowregular';
                        // color: ##000000;
                    }
                }
            }

            .cardTwo {
                width: 20vw;
                margin-left: 2vh;
                border: 1px solid rgba(33, 33, 33, 0.6);
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
            }
        }

        .btnContainer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 8%;
            margin-bottom: 5%;

            .backBtn {
                background: #ffffff;
                border: 1px solid #fb651f;
                border-radius: 4px;
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                color: #fb651f;
                font-family: "robotoregular";

                img {
                    margin-right: 1vh;
                }
            }

            .nextBtn {
                background: #fb651f;
                box-shadow: none;
                border-radius: 4px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                color: #ffffff;
                margin-right: 0vh;
                font-size: 14px;
                font-weight: 500;
                font-family: 'barlowregular';

                img {
                    margin-left: 1vh;
                }

            }
        }
    }
}

::-webkit-scrollbar {
    width: 1px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #212121;
}

::-webkit-scrollbar-thumb:hover {
    background: #212121;
}