.mainContainer{
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .title{
        font-size: 5vw;
        color: red;
    }
}