@font-face {
    font-family: 'barlowregular';
    src: url('../../../Fonts/fonts/barlow-regular-webfont.woff2') format('woff2'),
        url('../../../Fonts/fonts/barlow-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotoregular';
    src: url('../../../Fonts/fonts/roboto-regular-webfont.woff2') format('woff2'),
        url('../../../Fonts/fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 14%;

    .subContainer {
        max-width: 45vw;
        display: flex;
        flex-direction: column;
        

        .title {
            font-family: 'barlowregular';
            font-style: normal;
            font-weight: 400;
            font-size: 34px;
            line-height: 40px;
            text-align: center;
            letter-spacing: 0.25px;
            color: #0f2453;
            
        }

        .subTitle {
            margin-top: 3%;
            font-family: "barlowregular";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: rgba(33, 33, 33, 0.6);
            margin-bottom: 5%;


        }

        .card {
            // width: 80%;
            margin: 0 6vh 0 9vh;
            // min-height: 16vh;
            background: rgba(235, 234, 236, 0.2);
            border: 1px solid #e0e0e0;
            color: #21212199;
            font-family: 'barlowregular';
            border-radius: 8px;
            padding: 2vh 0 2vh 4vh;
            box-shadow: none;


        }

        .formContent {
            font-size: 16px;
            font-family: 'barlowregular';
            line-height: 20px;
            padding-top: 17px;
        }

        .pointIcon {
            padding-right: 14px;
            font-family: 'barlowregular';
        }

        .button {
            margin-top: 8%;
            margin-bottom: 8%;
            margin-left: 9vh;
            margin-right: 6vh;
            background: #fb651f;
            font-weight: 500;
            box-shadow: none;
            border-radius: 4px;
            font-size: 14px;
            padding: 10px 0px 7px 0; 
            font-family: 'barlowregular';

            img {
                margin-left: 1vh;
                margin-bottom: 2px;
            }
        }
    }
}