@font-face {
    font-family: 'barlowregular';
    src: url('../../../Fonts/fonts/barlow-regular-webfont.woff2') format('woff2'),
    url('../../../Fonts/fonts/barlow-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  
  @font-face {
    font-family: 'robotoregular';
    src: url('../../../Fonts/fonts/roboto-regular-webfont.woff2') format('woff2'),
      url('../../../Fonts/fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }



.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    .subContainer {
        width: 40vw;
        height: 51px;
        margin-top: 25%;

        display: flex;
        flex-direction: column;
        .title {
            font-family: "barlowregular";
            font-style: normal;
            font-weight: 400;
            font-size: 34px;
            line-height: 40px;
            text-align: center;
            letter-spacing: 0.25px;
            color: #0f2453;
        }
        .subTitle {
            margin-top: 3%;
            font-family: "barlowregular";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: rgba(33, 33, 33, 0.6);
            margin-bottom: 5%;
        }
        .btnContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20%;
            .backBtn {
                background: #ffffff;
                border: 1px solid #fb651f;
                border-radius: 4px;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 14px;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                color: #fb651f;
                font-family: 'robotoregular';
                img{
                    margin-right: 1vh;
                }
            }
            .nextBtn {
                background: #fb651f;
                box-shadow: none;
                border-radius: 4px;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 14px;
                text-align: center;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                color: #ffffff;
                font-family: 'barlowregular';
                img{
                    margin-left: 1vh;
                }
            }
        }
    }
}
