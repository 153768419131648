@font-face {
    font-family: 'barlowregular';
    src: url('../../../../Fonts/fonts/barlow-regular-webfont.woff2') format('woff2'),
        url('../../../../Fonts/fonts/barlow-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotoregular';
    src: url('../../../../Fonts/fonts/roboto-regular-webfont.woff2') format('woff2'),
        url('../../../../Fonts/fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


.mainContainer {
    padding: 0;
    margin: 0;
    width: 40vw;
    height: 55vw;

    .summaryTitle {
        font-family: "barlowregular";
        font-style: normal;
        font-weight: 400;
        font-size: 2.1vw;
        line-height: 2.5vw;
        letter-spacing: 0.25px;
        color: #0f2453;
    }

    .summarySubTitle {
        font-family: "barlowregular";
        font-style: normal;
        font-weight: 500;
        margin-bottom: 4vh;
        margin-top: 2vw;
        font-size: 1.4vw;
        line-height: 1.8vw;
        letter-spacing: 0.15px;
        color: rgba(33, 33, 33, 0.6);
    }


    .addressContainer,
    .addressSubContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4vh;
        padding-bottom: 2%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        cursor: pointer;

        .addressTitle {
            font-family: "barlowregular";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.1vw;
            letter-spacing: 1.25px;
            text-transform: uppercase;
            color: rgba(33, 33, 33, 0.6);
        }

    }

    .addressSubContainer {
        border: none;
    }

    .zone {
        font-family: 'robotoregular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.25px;
        color: #212121;
    }

    .addressSubContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2%;

        .addressSubCont {
            inline-size: 20vw;
            overflow-wrap: break-word;

            .addressSubTitle {
                font-family: "barlowregular";
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 2.1vw;
                color: #212121;
                width: 100%;
            }
        }

    }

    .infoIcon2 {
        margin-left: 1vh;
    }

    .userInfoContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .investTitle {
            font-family: "robotoregular";
            font-style: normal;
            font-weight: 400;
            padding-right: 9vh;
            font-size: 14px;
            margin-top: 4vh;
            line-height: 1.4vw;
            letter-spacing: 0.25px;
            color: rgba(33, 33, 33, 0.6);
        }

        .investAns {
            font-family: "robotoregular";
            font-style: normal;
            font-weight: 500;
            margin-top: 1vh;
            font-size: 14px;
            line-height: 1.4vw;
            letter-spacing: 0.1px;
            color: #212121;
            width: 10vw;
            word-wrap: break-word;
        }



        .investContainerone {
            margin-right: -3.2ch;
        }




    }

    .jadu {
        font-family: 'robotoregular';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        padding-top: 0vh;
        padding-bottom: 2vh;
        letter-spacing: 0.1px;
        color: #212121;

    }


    .option {
        margin-top: 2vh;
        text-transform: capitalize;
        color: rgba(33, 33, 33, 0.6);
        font-family: 'barlowregular';
        font-size: 14px;
        font-weight: 500;
    }


    .optionContainer {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        margin-top: 1vh;

        .optionTitle {
            font-family: "barlowregular";
            font-style: normal;
            font-weight: 500;
            font-size: 1vw;
            line-height: 1.2vw;
            letter-spacing: 1.25px;

            color: rgba(33, 33, 33, 0.6);
        }

        .Option2 {
            margin-top: 2vh;
        }

        .option {
            font-family: "barlowregular";
        }




        .squareFeet {
            margin-top: 0vh;
            font-family: 'robotoregular';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            letter-spacing: 0.25px;

            /* Text Dark 2nd */

            color: rgba(33, 33, 33, 0.6);



        }




        .values {
            font-family: 'robotoregular';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            margin-top: 0.5vh;
            letter-spacing: 0.1px;
            color: #212121;
            margin-bottom: 2vh;
        }
    }
}