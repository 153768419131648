.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .spinner {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #fb651f;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin-top: 20%;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  