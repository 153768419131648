.mainContainer {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;

}

.headContainer {
  position: fixed;
  width: 100%;
  z-index: 1000;

}

.stepsWrapper {
  width: 100%;
  float: left;
  background: #0F2453;
  height: 104px;
}

.stepssec {
  width: 66vw;
  margin: 0 auto;
}

.stepssecinner {
  width: 100%;
  float: left;
  padding-top: 2%;
}

.stepsflow {
  width: 100%;
  margin: 0 auto;
}

.stepperWrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
}

.stepperItem {
  // position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

@media (max-width: 768px) {}

.stepperItem::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepperItem::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepperItem .stepCounter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f8f8f8;
  margin-bottom: 8px;
}

.stepperItem.active {
  font-weight: bold;
}

.stepperItem.completed .stepCounter {
  background-color: #fa651f;
}

.stepperItem.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #fa651f;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepperItem:first-child::before {
  content: none;
}

.stepperItem:last-child::after {
  content: none;
}

.stepName {
  font-family: barlowregular;
  color: #fff;
  font-weight: normal;
  line-height: 19.2px;
  font-size: 16px;
}

.bgOrange {
  background-color: #fa651f !important;
  width: 35px;
  height: 35px;
}

.stepper {
  position: relative;
  border: 1px solid #fff;
  top: 1.3vw;
  width: 75%;
  left: 9vw;
}

.progress-bar {
  top: -48px;
  width: 100%;
  left: 9vw;
}

.css-1enr56f-MuiLinearProgress-root {
  background-color: #fff !important;
}
.css-1hx572w{
  background-color: #fff !important;
}