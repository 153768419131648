@font-face {
    font-family: 'barlowregular';
    src: url('../../../Fonts/fonts/barlow-regular-webfont.woff2') format('woff2'),
    url('../../../Fonts/fonts/barlow-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }
  
  @font-face {
    font-family: 'robotoregular';
    src: url('../../../Fonts/fonts/roboto-regular-webfont.woff2') format('woff2'),
      url('../../../Fonts/fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
  }

.mainContainer {
    padding: 0;
    margin-top: 20%;
    display: flex;  
    align-items: center; 
    justify-content: center;
    @media (min-width: 400px) and (max-width: 800px) {
        margin-top: 30%;      
    }
    @media (min-width: 800px) and (max-width: 1200px) {
            margin-top: 25%;      
    }

    @media (min-width: 1200px) and (max-width: 16   00px) {
                margin-top: 20%;      
    }
        
    .subContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        .innerContainer {
            display: flex;
            justify-content: space-between;
        }
        .component{
            width: 35%;
        }
    
    }
}

::-webkit-scrollbar {
    width: 1px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #212121;
}

::-webkit-scrollbar-thumb:hover {
    background: #212121;
}
