.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;

    .subContainer {
        width: 48vw;
        margin-top: 18%;

        .titleContainer {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            .title {
                font-family: "barlowregular";
                font-style: normal;
                font-weight: 400;
                font-size: 34px;
                line-height: 2.6vw;
                text-align: left;
                letter-spacing: 0.25px;
                color: #0f2453;
            }

            .subTitle {
                margin-top: 2%;
                font-family: "barlowregular";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 2.1vw;
                text-align: left;
                color: rgba(33, 33, 33, 0.6);
                margin-bottom: 5%;
            }

            .address {
                font-family: "barlowregular";
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 1.5vw;
                letter-spacing: 0.15px;
                color: #0f2453;
                margin-bottom: 1vh;
            }
        }

        .addressContainer {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            .addressSubContainer {
                display: flex;
                align-items: space-between;
                justify-content: space-between;
                margin-top: 2vh;
                margin-bottom: 2.6vh;

                .addressTitle {
                    font-family: "barlowregular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.3vw;
                    line-height: 2.1vw;
                    color: #212121;
                }

                .addressSubTitle {
                    font-family: "barlowregular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.3vw;
                    line-height: 2.1vw;
                    color: #212121;
                    width: 35ch;
                    word-break: break-word;
                }

                .chipBtn {
                    background-color: #fb651f;
                    font-size: 14px;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    color: #ffffff;
                    border: none;
                    letter-spacing: 0.25px;
                }
            }

            .propertyContainer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 2vh;
                margin-top: 6vh;

                .propertyTitle {
                    font-family: "barlowregular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.3vw;
                    letter-spacing: 0.15px;
                    color: #212121;
                }

                .propertyToggleBtnContainer {
                    .ownActiveBtn {
                        background: #0f2453;
                        border: 1px solid rgba(33, 33, 33, 0.6);
                        border-radius: 4px;
                        font-family: "robotoregular";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 1.3vw;
                        color: #ffffff;
                        text-transform: none;
                        padding: 1vh 5vh 1vh 5vh;
                    }

                    .ownInActiveBtn {
                        background: #ffffff;
                        border: 1px solid rgba(33, 33, 33, 0.6);
                        border-radius: 4px;
                        font-family: "barlowregular";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 1.3vw;
                        color: rgba(33, 33, 33, 0.6);
                        text-transform: none;
                        padding: 1vh 5vh 1vh 5vh;
                    }
                }
            }
        }

        .residenceContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            padding-top: 4vh;
            padding-bottom: 2vh;

            .residenceTitle {
                font-family: "barlowregular";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 1.3vw;
                letter-spacing: 0.15px;
                color: #212121;
            }

            .residenceBtnContainer {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .primaryActiveBtn {
                    background: #0f2453;
                    border: 1px solid rgba(33, 33, 33, 0.6);
                    border-radius: 4px;
                    font-family: "robotoregular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.3vw;
                    letter-spacing: 0.15px;
                    color: #ffffff;
                    text-transform: none;
                    padding: 1vh 3.2vh 1vh 3.2vh;
                    text-align: center;
                }

                .primaryActiveBtn1 {
                    background: #0f2453;
                    border: 1px solid rgba(33, 33, 33, 0.6);
                    border-radius: 4px;
                    font-family: "robotoregular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.3vw;
                    color: #ffffff;
                    text-transform: none;
                    padding: 1vh 3.5vh 1vh 3.5vh;

                    @media (min-width: 600px) and (max-width: 768px) {
                        padding: 1vh 2.7vh 1vh 2.7vh;
                    }

                    @media (min-width: 769x) and (max-width: 1024px) {
                        padding: 1vh 2.7vh 1vh 2.7vh;
                    }

                    @media (min-width: 1025px) and (max-width: 1200px) {
                        padding: 1vh 2.7vh 1vh 2.7vh;
                    }

                    @media (min-width: 1201px) and (max-width: 1600px) {
                        padding: 1vh 3.1vh 1vh 3.1vh;
                    }
                }

                .primaryInActiveBtn1 {
                    background: #ffffff;
                    border: 1px solid rgba(33, 33, 33, 0.6);
                    border-radius: 4px;
                    font-family: "barlowregular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.3vw;
                    color: rgba(33, 33, 33, 0.6);
                    text-transform: none;
                    padding: 1vh 3.5vh 1vh 3.5vh;

                    @media (min-width: 600px) and (max-width: 768px) {
                        padding: 1vh 2.7vh 1vh 2.7vh;
                    }

                    @media (min-width: 769x) and (max-width: 1024px) {
                        padding: 1vh 2.7vh 1vh 2.7vh;
                    }

                    @media (min-width: 1025px) and (max-width: 1200px) {
                        padding: 1vh 2.7vh 1vh 2.7vh;
                    }

                    @media (min-width: 1201px) and (max-width: 1600px) {
                        padding: 1vh 3.1vh 1vh 3.1vh;
                    }
                }

                .primaryInActiveBtn {
                    background: #ffffff;
                    border: 1px solid rgba(33, 33, 33, 0.6);
                    border-radius: 4px;
                    font-family: "barlowregular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.3vw;
                    letter-spacing: 0.15px;
                    color: rgba(33, 33, 33, 0.6);
                    text-transform: none;
                    padding: 1vh 3.2vh 1vh 3.2vh;
                    text-align: center;
                }
            }
        }

        .goalContainer {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            padding-bottom: 2vh;

            .goalTitle {
                font-family: "barlowregular";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 1.3vw;
                letter-spacing: 0.15px;
                color: #212121;
                margin-bottom: 2vh;
                margin-top: 6vh;
            }

            .drop {
                color: #212121;
            }
        }

        .projectSpecContainer {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            padding-bottom: 2vh;

            .projectTitle {
                font-family: "barlowregular";
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 2.1vw;
                color: #0f2453;
                margin-top: 4vh;
                margin-bottom: 4vh;
            }

            .projContainer {
                .projTitle {
                    font-family: "barlowregular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.1vw;
                    line-height: 1.3vw;
                    letter-spacing: 0.15px;
                    color: #0f2453;
                    margin-bottom: 3vh;
                }
            }
        }

        .serviceContainer {
            .serviceTitle {
                font-family: "barlowregular";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 1.6vw;
                letter-spacing: 0.15px;
                color: #212121;
                margin-bottom: 2%;
                margin-top: 4%;
            }

            .serviceBtnContainer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                .selectedBtn1,
                .selectedBtn2 {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 16px 0px;
                    width: 15.2vw;
                    height: 2vh;
                    background: #0f2453;
                    border-radius: 4px;
                    font-family: "barlowregular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.3vw;
                    letter-spacing: 0.15px;
                    color: #ffffff;
                    padding-top: 1.3vw;
                    padding-bottom: 1.3vw;
                }

                .selectedBtn2 {
                    margin-top: 2vh;
                }

                .notSelectedBtn1,
                .notSelectedBtn2 {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 16px 0px;
                    width: 15.2vw;
                    height: 2vh;
                    background: #ffffff;
                    border-radius: 4px;
                    font-family: "barlowregular";
                    color: #21212199;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    border: 1px solid rgba(33, 33, 33, 0.6);
                    line-height: 1.3vw;
                    letter-spacing: 0.15px;
                    padding-top: 1.3vw;
                    padding-bottom: 1.3vw;
                }

                .notSelectedBtn2 {
                    margin-top: 2vh;
                }
            }
        }

        .btnContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 8vh;
            padding-bottom: 8vh;

            .backBtn {
                background: #ffffff;
                border: 1px solid #fb651f;
                border-radius: 4px;
                display: flex;
                font-weight: 500;
                align-items: center;
                text-align: center;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                color: #fb651f;
                font-family: "robotoregular";

                img {
                    margin-right: 1vh;
                }
            }

            .nextBtn {
                background: #fb651f;

                border-radius: 4px;
                display: flex;
                font-weight: 500;
                align-items: center;
                text-align: center;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                color: #ffffff;
                font-family: "barlowregular";
                box-shadow: none;
                border: none;

                img {
                    margin-left: 1vh;
                }
            }
        }
    }
}