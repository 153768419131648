.mainContainer {
    margin: 0;
    padding: 0;
    .paymentContainer{
       display: flex;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       .title{
        font-size: 4vw;
        color: red;
        text-align: start;
       }
       .message{
        margin-top: 4vh;
        font-size: 2vw;
        text-align: start;
       }
    }
}