.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    .subContainer {
        width: 45vw;
        margin-top: 22%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .title {
            font-family: "barlowregular";
            font-style: normal;
            font-weight: 400;
            font-size: 34px;
            line-height: 2.6vw;
            text-align: center;
            letter-spacing: 0.25px;
            color: #0f2453;
            margin-bottom: 1vh;
            // width: 254px;
            // height: 40px;
        }

        .subTitle {
            font-family: "barlowregular";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 2.1vw;
            text-align: left;
            color: rgba(33, 33, 33, 0.6);
            margin-bottom: 5%;
            width: 100%;
            height: 20px;
        }

        .selectAdu {
            margin-top: 4.2vh;

            .selectTitleContainer {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 1vw;

                .selectTitle {
                    font-family: "barlowregular";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 1.7vw;
                    letter-spacing: 0.15px;
                    color: #0f2453;

                }

                img {
                    margin-left: 1vh;
                }
            }

            .selectSubTitle {
                font-family: "robotoregular";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 1.8vw;
                letter-spacing: 0.44px;
                color: rgba(33, 33, 33, 0.6);
                margin-bottom: 5%;
            }

            .selectCardContainer {
                display: flex;
                align-items: center;
                flex-direction: row;
                margin-bottom: 2vh;
                cursor: pointer;

                .accessoryInActiveCard,
                .accessoryActiveCard {
                    margin-right: 2vh;
                    cursor: pointer;
                    border: 1px solid rgba(0, 0, 0, 0.12);
                    box-shadow: none;
                    border-radius: 8px;


                    input[type="checkbox"] {
                        accent-color: #0f2453;
                        margin: 1vh 1vh 0 1vh;
                        border: 1px solid #0f2453;
                        width: 2.5vw;
                        height: 2.5vh;
                    }

                    .checkBoxContainer {
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-end;
                        accent-color: #0f2453;
                        margin: 1vh;
                    }

                    .accessoryContent {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        width: 12vw;
                        height: 6vw;

                        img {
                            height: 5vh;
                        }

                        .accessoryCardTitle {
                            font-family: "barlowregular";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            text-align: center;
                            letter-spacing: 0.1px;
                            color: #14395b;
                        }
                    }
                }

                .accessoryActiveCard {
                    background: #ffffff;
                    border: 1px solid #0f2453;
                    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
                    border-radius: 8px;

                }

                .lastInActiveCard,
                .lastActiveCard {
                    margin-right: 2vh;
                    padding-left: 0vh;
                    padding-bottom: 6vh;
                    border-radius: 8px;
                    cursor: pointer;
                    border: 1px solid rgba(0, 0, 0, 0.12);
                    box-shadow: none;


                    input[type="checkbox"] {
                        accent-color: #0f2453;
                        margin: 1vh 1vh 0 1vh;
                        border: 1px solid #0f2453;
                        width: 2.5vw;
                        height: 2.5vh;
                    }

                    .lastCheckBoxContainer {
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-end;
                    }

                    .lastCardContent {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        width: 12vw;
                        height: 3.8vw;

                        img {
                            height: 3vh;
                            margin-top: 2vh;
                        }

                        .lastCardTitle {
                            font-family: "barlowregular";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            text-align: center;
                            letter-spacing: 0.1px;
                            color: #14395b;
                        }
                    }
                }

                .lastActiveCard {
                    background: #ffffff;
                    border: 1px solid #0f2453;
                    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
                    border-radius: 8px;

                }
            }
        }

        .specialContainer {
            margin-top: 5%;


            .specialTitle {
                font-family: "barlowregular";
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 1.6vw;
                letter-spacing: 0.15px;
                color: #0f2453;
                margin-bottom: 1vw;
            }

            .specialSubTitle {
                font-family: "robotoregular";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 1.8vw;
                letter-spacing: 0.44px;
                color: rgba(33, 33, 33, 0.6);
                margin-bottom: 8%;
            }

            .selectInputContainer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 43vw;


                @media screen and (min-width:1280px) {
                    width: 46vw;
                }

                @media screen and (min-width:1920px) {
                    width: 43vw;
                }
                @media screen and (min-width:1440px) {
                    width: 44vw;
                }


                .specialLeftInActiveBtn,
                .specialLeftActiveBtn {
                    margin-right: 1vh;
                    margin-bottom: 1.5vh;
                    background: #ffffff;
                    border: 1px solid rgba(33, 33, 33, 0.6);
                    border-radius: 4px;
                    font-family: "barlowregular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.3vw;
                    color: rgba(33, 33, 33, 0.6);
                    text-transform: capitalize;
                }

                .specialLeftActiveBtn {
                    background-color: #0f2453;
                    color: #ffffff;
                }



                .specialRightBtnInActiveBtn,
                .specialRightBtnActiveBtn {
                    margin-left: 1vh;
                    // width: 32vw;
                    margin-bottom: 1.5vh;
                    background: #ffffff;
                    border: 1px solid rgba(33, 33, 33, 0.6);
                    border-radius: 4px;
                    border-radius: 4px;
                    font-family: "barlowregular";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1.3vw;
                    color: rgba(33, 33, 33, 0.6);
                    text-transform: capitalize;
                }

                .specialRightBtnActiveBtn {
                    background-color: #0f2453;
                    color: #ffffff;
                }
            }
        }

        .btnContainer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 8%;
            margin-bottom: 5%;

@media screen and (min-width:1920px) {
    width: 95%;
    
}
@media screen and (min-width:1440px) {
    width: 97%;
    
}

            .backBtn {
                background: #ffffff;
                border: 1px solid #fb651f;
                border-radius: 4px;
                display: flex;
                font-family: 'robotoregular';
                font-size: 14px;
                font-weight: 500;
                align-items: center;
                text-align: center;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                color: #fb651f;

                img {
                    margin-right: 1vh;
                }
            }

            .nextBtn {
                background: #fb651f;
                box-shadow: none;
                border-radius: 4px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                color: #ffffff;
                font-size: 14px;
                font-weight: 500;
                margin-right: -1vh;
                font-family: 'barlowregular';




                img {
                    margin-left: 1vh;
                }
            }
        }
    }
}

::-webkit-scrollbar {
    width: 1px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #212121;
}

::-webkit-scrollbar-thumb:hover {
    background: #212121;
}