@font-face {
    font-family: 'barlowregular';
    src: url('../../../Fonts/fonts/barlow-regular-webfont.woff2') format('woff2'),
        url('../../../Fonts/fonts/barlow-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotoregular';
    src: url('../../../Fonts/fonts/roboto-regular-webfont.woff2') format('woff2'),
        url('../../../Fonts/fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    .subContainer {
        width: 43vw;
        margin-top: 22%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .title {
            font-family: "barlowregular";
            font-style: normal;
            font-weight: 400;
            font-size: 34px;
            line-height: 2.6vw;
            text-align: center;
            letter-spacing: 0.25px;
            color: #0f2453;
            height: 40px;
        }

        .subTitle {
            font-family: "barlowregular";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 2.1vw;
            // text-align: center;
            color: rgba(33, 33, 33, 0.6);
            margin-bottom: 5%;
            // margin-left: -2.4vw;
            width: 45vw;
        }

        .method {
            font-family: 'barlowregular';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: #0F2453;
            margin-bottom: 3vh;
        }

        .contactContainer,
        .paymentInformation {
            margin-bottom: 5%;
            width: 100%;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            padding-bottom: 1.5vw;

            .inputFields {
                display: flex;
                align-items: center;
                justify-content: space-between;
                // margin-right: 2vh;



                .firstName {
                    margin-top: 2vh;
                    margin-bottom: 2vh;
                    margin-right: 1vh;
                }

                .lastName {
                    margin-top: 2vh;
                    margin-bottom: 2vh;
                    margin-left: 1vh;
                }
            }
        }

        .paymentInformation {
            margin-top: 0%;
            width: 100%;
            border-bottom: none;

            .paymentContainer {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                margin-right: 1vw;


                .bankCard {
                    // width: 100%;
                    min-height: 5vw;
                    margin-right: 0;
                    margin-left: 1.5vh;
                    margin-bottom: 5%;
                    background: #fff;
                    width: 21vw;
                    border-radius: 8px;
                    border: 1px solid rgba(33, 33, 33, 0.6);
                    box-shadow: none;
                    cursor: pointer;



                    .radioBtn {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        padding-top: 1vh;
                        padding-right: 1vh;
                    }


                    .bankDetails {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: 'barlowregular';
                        color: #212121;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.15px;
                    }

                }


                .bankCard input[type="radio"] {
                    accent-color: #FB651F;
                    cursor: pointer;
                }

                .ownActiveBtn {
                    background-color: red;
                }

                .ownInActiveBtn {
                    background-color: #FB651F;
                }
            }

            .credit {
                margin: 0vh 2vh 0vh 0vh;
            }
        }


        .btnContainer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 8%;
            margin-bottom: 5%;

            .backBtn {
                background: #ffffff;
                border: 1px solid #fb651f;
                border-radius: 4px;
                display: flex;
                font-size: 14px;
                font-weight: 500;
                align-items: center;
                text-align: center;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                color: #fb651f;
                font-family: 'robotoregular';

                img {
                    margin-right: 1vh;
                }
            }

            .nextBtn {
                background: #fb651f;
                box-shadow: none;
                border-radius: 4px;
                display: flex;
                align-items: center;
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                color: #ffffff;
                font-family: 'barlowregular';

                img {
                    margin-left: 1vh;
                }
            }
        }
    }
}

::-webkit-scrollbar {
    width: 1px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #212121;
}

::-webkit-scrollbar-thumb:hover {
    background: #212121;
}